.question-hint{ 
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 10px;
}
.question-hint span{
  font-size: 14px;
  color: #656A6F;
  line-height: 140.62%;  
	font-weight: 500;
} 
.question-hint .MuiSvgIcon-root{
  height: 12px;
  color: #656A6F;
}