.footprint-personal-graph{
  color: #FFFFFF;  
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;	
	height: 22px;
  margin-top: 25px;
}
.footprint-personal-graph .odometer-value {
	font-family: Space Mono, sans-serif;
	line-height: 100%;
}
.footprint-personal-graph .tons{
  text-align: center;
}
.footprint-personal-graph .tooltip-button{ 
  color: #FFFFFF;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.footprint-personal-graph .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}

.footprint-personal-graph .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, 
.footprint-personal-graph .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner{
  display: flex;
  align-items: center;
}
.footprint-embed{
  display: flex;
  flex-direction: column;
  width: 260px;
  position: absolute;
  left: calc(50% + 170px);
  top:calc(50% - 110px)
}
.graph-popover{
  pointer-events: none;
}
.graph-popover .MuiPopover-paper{
  padding: 12px;
  
}
.mask-graph{
  top: 26px;
  display: block;  
  position: absolute;
  height: 260px;
  width: 260px;  
  z-index: -1;
}
.graph-popover .graph-popover-title  {
  text-align: center;
  display: block;
  font-size: 14px;

}
.graph-popover .graph-popover-text  {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 14px;


}
.footprint-embed .label,
.footprint-embed .value
{
  color: white;
}
.footprint-embed .section_selection {
  justify-content: space-between;
}

@media only screen and (max-width: 845px) {
  .footprint-embed{
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    position: initial;
    max-width: calc(600px - 25px);
  }  
  
}
