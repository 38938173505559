.section_selection{
  display: flex;
  align-items: center;
	color: #041E10;
}
.section_selection .icon{
  margin-right: 10px;
}
.section_selection .label{
  font-size: 16px;
  width: 120px;
  text-transform: capitalize;
}
.section_selection .value{
  text-align: right;
  font-family: Space Mono;
  width: 100px;
	font-weight:700;
}
.section_selection .value span{
  font-family: 'Roboto', sans-serif;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 400;
}
.section_selection .MuiCheckbox-root{
  color: #FFFFFF8A;
}
.section_selection .MuiSvgIcon-root.home{
  color: #239B8A;
}

.section_selection .MuiSvgIcon-root.transport{
  color: #34638B;
  transform: matrix(0.71, 0.73, -0.69, 0.71, 0, 0);
}

.section_selection .MuiSvgIcon-root.food{
  color: #BBBE2C;
}

.section_selection .MuiSvgIcon-root.goods{
  color: #91959B;
}

.section_selection .MuiSvgIcon-root.services{
  color: #5C6068;
}
.section_selection .icon-label{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section_selection  .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root{
  color: #041E10;
}
.section_selection .MuiCheckbox-colorPrimary.Mui-checked{
  color: #2c76d2;  
}
/*
#2c76d2
*/