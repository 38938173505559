
.enroll_login{
  height: 100vh;  
  display: flex;
  align-items: center;
  justify-content: center;
  background:#041e10;
}
.enroll_login .wrapper{
  flex: 1;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  
  background: #F9F9FD;
  border-radius: 4px;
  padding: 20px 20px 40px;
  text-align: center;
}
.enroll_login .wrapper .MuiCircularProgress-root{
  margin: 0 auto;
}
.enroll_login .wrapper form{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.enroll_login h1{
  text-align: center;
  font-size: 32px;
  font-weight:500;
}
.enroll_login footer{
  margin-top: 30px;
  text-align: center;
  font-size: 16px;
}
.enroll_login .ui.message {
  text-align: center;
}

.enroll_login .MuiInputBase-input {
border-radius:4px;
background-color:#ffffff;
}


#root .enroll_login button.MuiButtonBase-root .MuiButton-label {
  font-size: 16px;
}