
.question-text .MuiInputBase-root{
  background: #ffffff;
  max-width: 420px;
  height: 64px;
}
.question-text .MuiInputBase-root fieldset{
  top: -6px;
}
.question-text .MuiOutlinedInput-root{
  background-color: #ffffff;
  font-size: 18px;
}

@media only screen and (max-width: 420px) {  
  .question-text .MuiInputBase-root{
    width: auto;
  }
}