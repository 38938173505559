.tooltip-footprint .MuiPopover-paper{
  padding: 15px;
  max-width: 400px;
  font-size: 18px;
}
.tooltip-footprint{
  pointer-events: none;
}
.tooltip-footprint .MuiPopover-paper h4{
  margin-block-start: 0px;  
  margin-block-end: 10px;  
}
.tooltip-button{
  font-size: inherit !important;
  display: inline-flex;
}