.question-zipcode .MuiInputBase-root{
  background: #fff;
}
.question-zipcode .MuiFormControl-root input{
  color: #00151E;
}
.question-zipcode.MuiAutocomplete-root{
  width: 400px;
}
@media only screen and (max-width: 576px) {
  .question-zipcode.MuiAutocomplete-root{
    width: 100%;
  }
}