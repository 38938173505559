.question-multiple{
  display: flex;
  flex-direction: column;
  
}
.question-multiple label{
  font-size: 24px;
	margin-bottom: 10px;
}
.question-multiple .MuiInputBase-root{
  background: #fff;
}
.question-multiple fieldset{
  top: -6px;  
}
.question-multiple .question-control{
  width: 100%;
  display: flex;
  align-items: center;
}
.question-multiple .MuiInputBase-root{
  max-width: 420px;
  width: 100%;
}
.question-multiple .question{
  margin: 15px 0;
}
.question-multiple .question:nth-last-child(1){
  margin-bottom: 0px;
}

.question-multiple .question-control .tooltip-button {
	margin-left:10px;
}

.question-advance .question-multiple input {
	font-size:18px;
}

.question-multiple .question-control > div {
	height:64px;
}

.question-multiple .MuiOutlinedInput-input {
	font-size: 18px;
}