.vehicle { 
 display: flex;
 flex-direction: column; 
 border-top: 1px solid #00000066;
 margin-top: 33px;
}

#root .vehicle label{
  font-size: 20px; 
  margin-top: 30px;
  margin-bottom: 10px;
}

.vehicle .MuiInputBase-root{
  background-color: #ffffff;
}


#root .question-vehicles .vehicle h1 {
	font-size:32px;
	margin-bottom:0px;
}

.vehicle .per-gallon{
  max-width: 323px;
}

.vehicle .per-gallon fieldset{
  top:-6px;
}
.vehicle .driven{
  max-width: 420px; 
}
.vehicle .driven fieldset{
  top:-6px;
}

.vehicle .MuiToggleButton-label{
	font-size: 16px;
	line-height: 100%;
	text-transform: none;
	height: 30px;
	font-weight: normal;
}
.vehicle .MuiToggleButton-label span{

}
.vehicle .MuiToggleButton-root{
	color: rgba(0, 0, 0, 0.54);
	border-color: rgba(0, 0, 0, 0.25);
}

.vehicle .MuiToggleButton-root.Mui-selected{
	color: #041E10;
}
.vehicle.MuiToggleButton-root.Mui-selected:hover{
	background-color: rgba(0, 0, 0, 0.12);
}

#root .question-vehicles .MuiFormControl-root.driven > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: center;
  align-content: stretch;
}

#root .question-vehicles .MuiFormControl-root.driven > div > div {
	margin-right:10px;
}

#root .question-vehicles .MuiOutlinedInput-input {
	font-size: 18px;
}