.footprint_score_graph{
  position: absolute;
  top:105px;
}
.footprint_score_graph .title{  
  font-size: 18px;
  line-height:100%;
  text-align: center;  
  color: #FFFFFF;
}

.footprint_score_graph .footprint-type{  
  max-width: 154px;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.6;
	letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footprint_score_graph .footprint-type span{
  pointer-events: none;
  max-width: 110px;
  text-align: center;
}
.footprint_score_graph .footprint-personal{    
  position: relative;
  top: 80px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
	margin: 20px 0;
	height: 14px;
}

.footprint_score_graph .tons{
  text-align: center;
}

.footprint_score_graph .score{
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
}
.footprint_score_graph .score .footprint-link{
  color: #FFFFFF;
  text-decoration: none !important;
}
.footprint_score_graph .footprint-summary{
  font-size: 12px;
  line-height:100%;  
  color: #FFFFFF;  
  opacity: 0.6;
  text-align: center;
  margin-top: 6px;
  display: flex;
  justify-content: center;
	letter-spacing: 0.5px;
}

.footprint_score_graph .score .odometer-value{
  font-family: Space Mono;
}
.footprint_score_graph .footprint-summary .tooltip-button{ 
  color: #FFFFFF; 
}
.footprint_score_graph .footprint-summary .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}
.footprint_score_graph .footprint-summary .tooltip-button{
  padding-top: 0px;
  padding-bottom: 0px;
}
.footprint_score_graph .footprint-type .tooltip-button{
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
}
.footprint_score_graph .footprint-type .tooltip-button{ 
  color: #FFFFFF; 
}
.footprint_score_graph .footprint-type .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}


/** Small Devices: Portrait & Landscape **/
@media (min-width: 300px) {
	/**.nav_footprint {
	    margin-top: 0px;
	}**/
}