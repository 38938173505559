.question-advance{
  display: flex;
  flex-direction: column;
}
.question-advance .engine_type{
  margin-bottom: 20px;
}
.question-helper .MuiToggleButton-label{
  font-size: 16px;
  line-height: 100%;
  text-transform: none;
  height: 30px;
	font-weight: normal;
}
.question-helper .MuiToggleButton-root{
  color: rgba(0, 0, 0, 0.54);
  border-color: rgba(0, 0, 0, 0.25);
}
.question-helper .MuiToggleButton-root.Mui-selected{
  color: #041E10;
}
.question-helper .MuiToggleButton-root.Mui-selected:hover{
  background-color: rgba(0, 0, 0, 0.12);
}
