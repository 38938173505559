.responses{
  background: #F9F9FD;
  display: flex;
  flex: 1;      
  padding: 20px 0 40px;    
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.responses .controls-filter{
  max-width: 600px;
}
.responses .controls-embed{
    display: none;
}
.responses .controls{
  margin-right: 28px;
}
.responses .controls-filter.MuiAccordion-root.Mui-expanded,
.responses .controls-filter.MuiAccordion-root
  {
  display: block;
  width: calc(100% - 20px);
  margin-left:10px;
  margin-right:10px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.responses .controls-filter .MuiAccordionDetails-root{
  flex-direction: column;    
  
}

.responses .controls-filter .section_selection{
  justify-content: space-between;
}
.responses .controls-filter.MuiPaper-root{
  /**box-shadow: none;**/
  border-radius: 4px;
} 
.responses .controls-filter.MuiPaper-root::before{
  background-color: transparent;
} 
.responses .table{
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
.edit-section{
  position: fixed;
  left: 15px;
  bottom:15px;
}
#root .edit-section .edit-button.MuiButtonBase-root.MuiButton-contained{
  height: 42px;
}

.responses footer.nav .navigation-buttons {
  margin-left: 20px;
  margin-top:  24px;
  margin-bottom: 38px;
}
.responses footer.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;    
  pointer-events: none;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

#root .responses footer.nav .MuiButton-root {
  height: 42px;
	font-size:16px;
  pointer-events: initial;
}

#root .responses footer.nav .MuiButton-root .MuiButton-label{
	font-size:16px;
}

#root .responses footer.nav a.MuiButton-root {
  text-decoration: none;
}

#root .responses footer.nav .navigation-buttons .MuiButtonBase-root {
  padding:0px;
  min-width: 42px;
  max-width: 42px;
	margin-right:2px;
	border-radius:0px;
}
#root .responses footer.nav .navigation-buttons .nav-btn-menu.MuiButtonBase-root {
  max-width: initial;
  padding-right: 10px;
  padding-left: 10px;
  border-radius:5px;
  margin-left: 10px;
}
#root .responses footer.nav .navigation-buttons .nav-btn-menu.MuiButtonBase-root span.text {
  height: 25px;
  padding-left: 5px;
  display: flex;
    justify-content: center;
    align-items: center;
}

#root .responses footer.nav .navigation-buttons .nav-btn-prev {
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

#root .responses footer.nav .navigation-buttons .nav-btn-next {
	-webkit-border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-bottomright: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

#root .responses footer.nav .navigation-buttons .nav-btn-prev svg{
	position:relative;
	left:5px;
}

@media only screen and (max-width: 845px) {
  .responses .controls-embed .section_selection{
    justify-content: space-between;
  }  
  .responses .controls-embed{
    display: block;
    width: calc(100% - 20px);
    margin-left:10px;
    margin-right:10px;
    margin-bottom: 10px;    
    background-color: #041e10;
    color: white;
}
  
  .responses .table{
    width: calc(100% - 20px);
    margin-left:10px;
    margin-right:10px;
    display: flex;
    justify-content: center;
    max-width: 600px;
  }
  .responses .controls{
    display: none;
  }
  
  
}