.footprint_score .title{  
  font-size: 18px;
  line-height:100%;
  text-align: center;  
  color: #FFFFFF;
}
.nav_footprint {
    margin-top: -30px;
}
.footprint_score .footprint-type{  
  margin-top: 16px;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.6;
	letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footprint_score .footprint-personal{    
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
	margin: 20px 0;
	height: 14px;
}

.footprint_score .footprint-personal .odometer-value {
	font-family: Space Mono, sans-serif;
	line-height: 100%;
}

.footprint_score .score{
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
}
.footprint_score .score .footprint-link{
  color: #FFFFFF;
  text-decoration: none !important;
}
.footprint_score .footprint-summary{
  font-size: 14px;
  line-height:100%;  
  color: #FFFFFF;  
  opacity: 0.6;
  text-align: center;
  margin-top: 6px;
  display: flex;
  justify-content: center;
	letter-spacing: 0.5px;
}
.footprint_score .footprint-personal .personal-label{
	letter-spacing:1px;
}
.footprint_score .score .odometer-value{
  font-family: Space Mono;
}
.footprint-summary .tooltip-button{ 
  color: #FFFFFF; 
}
.footprint-summary .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}
.footprint-summary .tooltip-button{
  padding-top: 0px;
  padding-bottom: 0px;
}
.footprint-type .tooltip-button{
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.footprint-type .tooltip-button{ 
  color: #FFFFFF; 
}
.footprint-type .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}
.footprint-personal .tooltip-button{
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.footprint-personal .tooltip-button{ 
  opacity: 0.6;
  color: #FFFFFF; 
}
.footprint-personal .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}

/** Small Devices: Portrait & Landscape **/
@media (min-width: 300px) {
	/**.nav_footprint {
	    margin-top: 0px;
	}**/
}
@media only screen and (max-width: 666px) {
  .footprint_score .title{
    display: none;
  }
}
